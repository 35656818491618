@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@300;400&display=swap');


.pricing {
    margin: 130px 0px 70px;
    text-align: center;
}

.price-card {
    font-family: 'Commissioner', sans-serif;
    margin: 50px auto;
    background-color: #000;
    background-image: url('../../public/images/bg-line.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    border-radius: 20px;
    padding: 70px 30px;
    max-width: 700px;
}

.price-card p {
    line-height: 1.8em;
    text-align: center;
}

.price-card h2 {
    font-weight: 500;
}

.price-card img {
    width: 40%;
    margin: 20px 0px;
}