@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@300;400;500;700&family=Poppins:wght@300;400;700&display=swap');

:root {
    --prm-color: #FF6B00;
}

@font-face {
    font-family: 'panamera';
    src: url('../../public/fonts/Panamera-Regular.otf') format('otf'),
        /* url('fonts/Panamera-Bold.otf') format('otf'), */
      
        url('../../public/fonts/Panamera-Light.otf') format('otf');
        
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'panamera', 'Poppins', sans-serif;

}

footer,.vessel,
.learn-more,
.jet, .integrate,
.payment,
.faqs, .about,.privacy,
.navbar,
.aside-info p {
    font-family: 'Poppins', sans-serif;
}

.container-header{
    padding:0 4em;
}
.container {
    max-width: 1250px;
    margin: auto;
    overflow: hidden;
    padding: 0px 20px;
    position: relative;
}

.logo img {
    height: 60px;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
}

.pay-vessel {
    width: 100%;
    z-index: 100;
    background-color: #fff;
    position: fixed;
    padding: 10px 0px;
    top: 0;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: #000;
    margin-right: 15px;
    font-size: 16px;
    padding: 6px;
    cursor: pointer;
}



a:hover {
    color: #FF6B00;
}

p {
    line-height: 2rem;
    font-size: 16px;
    text-align:justify;
}

.nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-list li a {
    font-size: 16px;
    font-weight: 500;

}



.nav-list li:nth-child(5) {
    border-right: 1px solid #ccc;
    margin-right: 20px;
}

.btn.first {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    text-decoration:none;
}

.btn,
.btn-prm {
    background-color: var(--prm-color);
    padding: 10px 25px;
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
}

.btn:hover,
.btn-prm:hover {
    background-color: black;

    color: #fff !important;

}


.btn a,
a .btn {
    color: #fff;
    cursor: pointer;
}

.prm-color {
       background-color: var(--prm-color);
    padding: 3px 10px;
    color: #fff;
    border-radius: 3px;
}

.flex {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 20px;
}

.aside-info {
    margin: 20px 0px;
}

.aside-info h2 {
        font-size: 3rem;
    line-height: 4rem;
}

.aside-info p {
    font-size: 14px;
    margin: 20px 0px;
    max-width: 400px;
    line-height: 1.5em;
    /* letter-spacing: -1px; */
    word-spacing: 0px;
}

.btn-section button.btn {
    display: inline-flex;
    align-items: center;
    height: 55px;
    padding: 15px;
    width: 100%;
    font-size: 17px;
    justify-content: center;
}

.btn-section img {
    height: 55px;
    margin-right: -30px;
    padding-left: 10px;
}

.btn-section {
    justify-content: start;
    gap: 20px;
    margin-top: 40px;
}

.btn-section .btn-dark {
    background-color: #000;
    color: #fff;
    padding: 7px 20px;
    border: none;
    height: 55px;
    border-radius: 3px;
}

.heropic {
    height: 600px;
    width: 100%;
    margin-bottom: -150px;
}

.hero {
    margin: 150px 0px 50px;
}

.network img {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.payment {
    margin-top: 60px;
}

.center,
.center p {
    text-align: center;
    line-height: 2.3rem;
}

.card {
    background-color: #FFF0E3;
    padding: 35px 25px;
    border-radius: 15px;
}

.cards {
    margin: 70px 0px 30px;
}


.card.one {
    width: 20%;
}

.card.one .flex {
    justify-content: space-between;
}

.card.two {
    width: 80%;
    line-height: 2.5rem;
}

.set-img {
    height: 40px;
}

/* .learn-more {
    margin: 70px 0px;
    align-items: center;
    position: relative;
} */

.learn-more {
    margin: 150px 0px;
    background-color: #000000;
    padding: 4em 0 4em 4em  ;
}

.learn-more-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 30px 0 30px 30px; */

}

.learn-more-container h1{
    font-weight: 700;
    font-size: 48px;
    line-height: 70.93px;
    font-family: 'Poppins', sans-serif;
    color: #F6F6F6;
    margin-bottom: 0.5em;
}

.learn-more-container p{
    font-weight: 300;
    font-size: 18px;
    /*letter-spacing:1px;*/
    line-height: 30px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    color: #F6F6F6;
    margin-bottom: 0.6em;
}

.learn-more-container .learn-more-content{
    width: 40%;
}

.learn-more-container .learn-more-image{
    width: 60%;
}

.learn-more-container .learn-more-image img{
    width: 100%;
}

@media(max-width:880px) {

    .learn-more {
        margin: 30px 0px;
        padding: 2em;
    }

    .learn-more-container{
        flex-direction: column-reverse;
    }

    .learn-more-container h1,
    .platform-text h2 {
        
        font-size: 30px;
        margin-bottom: 0.6em;
        line-height: 30.93px;
       
    }
    
    .learn-more-container p,
    .platform-text p {
       
        font-size: 16px;
        line-height: 28.24px;
    }

    .learn-more-container .learn-more-content,.learn-more-container .learn-more-image{
        width: 100%;
    }

    .learn-more-container .learn-more-image img{
        height: 100%;
        height: 100%;
        margin-bottom: 2em;
    }
    
    
}



.eclipse {
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 200px;
    width: 25%;
}

.we-are-section{
    margin: 4em 3.8em;
    margin-bottom: 150px;
}

.we-are-section .we-are-container h3{
    text-align: center;
}

.we-are-flex{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}

.we-are-card{
    padding: 2em 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #FFF8F3;
    border-radius: 10px;
    gap: 15px;
}

/* .we-are-card:hover{
    background-color: #FFF8F3;

} */


.we-are-tag{
    
    width: 80%;
    /* position: absolute;
    top: 5px;
    left: 20px; */
}

.we-are-tag img{
    height: 30px; 
}

.we-are-icon{
    background-color: #FF6B00;
    width: 308px;
    height: 308px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.we-are-icon:hover{
    background-color: black;
   
}

.we-are-icon h3{
    margin-top:1em;
  color: white;
  font-weight: 600;
  font-size: 28px;
  line-height: 39.14px;
  text-align: center;
}



.we-are-content{
    text-align: center;
    font-weight: 400px;
    font-size: 18px;
    line-height: 33px;
    color: #1B1C1E;
    font-family: 'Poppins', sans-serif;
}
.we-are {
    text-align: center;
    margin-bottom: 50px;
}




@media(max-width:880px) {

    .we-are-flex{
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
    }

    .we-are-section{
        margin: 2em 1em;
    }

    .we-are-tag{
    
        width: 100%;
       
    }

    .we-are-icon{
      
        width: 280px;
        height: 280px;
        
    }

    .we-are-content{
      
        font-size: 18px;
        
    }
    

}



.flex.img-no {
    justify-content: space-between;
    flex-wrap: wrap;
}

.flex.img-no img {
    height: 350px;
    width: 300px;

}


.platform-section{
    background-color: black;
    padding: 6em 4em;
}


.platform-container{
    display: flex;
    justify-content:space-between;
    align-items: stretch;
    


}
.platform-img,.platform-text{
    width: 50%;
    
}
.platform-img-shadow{

       
    
    width: 700px;
        border-radius: 20px;
      
        background-color: #FF6B00;
        padding: 10px ;
       width: 720px;

    
}

.platform-img img{
    
    width: 700px;
    height: 600px;
    margin-bottom: -3px;
   
}

.platform-text{

 padding-left: 3em;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.platform-text h2{
    color: white;
    font-weight: 700px;
    font-size: 48px;
    line-height: 68px;
    font-family: 'Poppins', sans-serif;
  }


  .platform-text p{
    color: white;
    font-weight: 400px;
    font-size: 20px;
    line-height: 40px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: -1px;
  }

.platform-btn{
    margin-top: 2em;
    font-size: 20px;
}

.platform-btn:hover,
.learn-more-btn:hover {
    background-color: var(--prm-color);
    opacity: 0.9;
}

.btn.wide {
    padding: 20px;
    font-size: 20px;
}



@media(max-width:880px) {

    .btn.wide {
        padding: 10px 15px;
        font-size: 18px;
    }

    .platform-container{
        flex-direction: column;
    }

    .platform-img,.platform-text{
        width: 100%;
    }

    .platform-text{
       margin-top: 2em;
       padding: 0;
       }

       .platform-section{
        
        padding:2em;
    }

    /* .platform-text h2{
        
        font-size: 28px;
        line-height: 38px;
      }
    
    
      .platform-text p{

        font-size: 16px;
        line-height: 24px;
      } */
    
      .platform-btn{
        margin-top: 1em;
    }
    


}



.set-img-2 {
    /* height: 400px; */
    width: 100%;
    border-radius: 25px;
}

.integrate {
    margin: 150px 0px;
}

.integrate-container{
    padding:0 3em ;
}
.integrate-container h2{
    /*font-weight: 700;*/
    /*font-size: 38px;*/
    /*line-height: 57px;*/
    font-family: 'Poppins', sans-serif;
}

.integrate-container p{
    font-weight: 400;
    font-size: 16px;
    line-height: 35.5px;
    font-family: 'Poppins', sans-serif;
}

.integrate-container img{
    margin-top: 4em;
}


@media(max-width:880px) {

    .integrate-container{
        padding:0 1em ;
    }
    
    .integrate-container p{
        font-weight: 400;
        font-size: 14px;
        line-height: 24.5px;
        font-family: 'Poppins', sans-serif;
        margin-top: 1em;
    }

    .integrate-container img{
        margin-top: 2em;
    }
    
    
}


.feedback {
    margin: 150px 0px;
}


.feedback-container{

    padding:0 3em ;
}

.feedback-container h2{
    /*font-weight: 700;*/
    /*font-size: 38px;*/
    /*line-height: 57px;*/
    color:#1B1C1E;
    font-family: 'Poppins', sans-serif;

}


.feed-bk-txt {
    text-align: center;
}

 .feedback-cards-list{
     margin-top: 3em;
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    gap: 1em;
}

.feedback-card{
    background-color: black;
    border-radius: 10px;
    padding: 2em;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1em;
}


.feedback-user-info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1em;

}
.feedback-user-img{
    background-color: #FF6B00;
    /* padding: 0.5em; */
   
    border-radius: 100%;
    display: flex;
    justify-content:center;
    align-items: center;

}

.feedback-user-img img{
    width: 105px;
    height: 100px;
   border-radius: 100%;

}

.feedback-user-data h3{

    font-weight: 500;
    font-size: 22px;
    line-height: 26.91px;
    font-family: 'Poppins', sans-serif;
    color: white;
}

.feedback-user-data p{

    font-weight: 300;
    font-size: 16px;
    line-height: 22.01px;
    font-family: 'Poppins', sans-serif;
    color: white;
    letter-spacing: -1px;
}

.feedback-content p{
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
    /* text-align: justify; */
    color: white;
    font-family: 'Poppins', sans-serif;
}


@media(max-width:880px) {

    .platform-img-shadow{
        width: 100%;
    }
    
    .platform-img img{
        
        width: 100%;
       
    }

    .payment {
        margin-top: 40px;
    }

    .feedback {
        margin: 70px 0px;
    }
    
    .feedback-container{
    padding:0 0.5em ;
    }

    .feedback-cards-list{
     flex-direction: column;
   }

   .feedback-user-img img{
    width: 75px;
    height: 70px;
   border-radius: 100%;

}

.feedback-user-data h3{

   
    font-size: 16px;
    line-height: 18.91px;
  
}

.feedback-user-data p{

    font-size: 14px;
    line-height: 20.01px;
    
}

.feedback-content p{
    font-size: 14px;
    line-height: 24px;
    
}
}



.feed-quote {
    margin: 50px 0px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.feed-quote img {
    height: 220px;
    width: 300px ;
    /* align-items: center;
    justify-content: center; */
    /* flex-shrink: 0; */
    height: auto;
    transition: transform 0.5s;
}

.my-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
}

.my-carousel.swiper {
    display: none;
}

.arr-sml {
    height: 80px;
    width: 80px;
    display: none;
}

.feed-img-top {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 200px;
    width: 30%;
}

.feed-img-bottom {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 200px;
    width: 30%;
}

.jet-container{
    padding: 0 3em;
    margin: 7em 0;
}

.start-jet {
    display: flex;
    flex-direction: column;
    background: url('../../public/images/bg-pattern.png') #050505;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    justify-content: center;
    border-radius: 20px;
   align-items: center;
   padding: 3em 0;
 
}

.start-jet-content{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0px;
    gap: 1em;
}


.start-jet h2 {
    font-size: 48px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    line-height: 52px;
    text-align: center;
}

.start-jet p {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    line-height: 32px;
    text-align: center;
   
}

.start-jet-buttons{
    margin-top: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
}

.start-jet-contact{
    height: 3.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.5em;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 7px;
    color: white;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.start-jet-register {
   display: flex;
   justify-content: flex-start;
   align-items: center;
}

.start-jet-register div{
    text-align: center;
    background-color: white;
    height: 3.5em;
    padding: 0 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    border-radius: 7px 0px 0px 7px;
}

.start-jet-register span{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 3.5em;
    padding: 0 1em;
    font-size: 18px;
    background-color: var(--prm-color);
    border-radius: 0px 7px 7px 0px;
}

@media(max-width:880px) {
    .start-jet-content {
        padding: 10px 0px;
    }

    .jet-container{
        padding: 0 1em;
        margin: 0.5em 0;
    }
    
    .start-jet {
       
       padding: 1em 0;
     
    }
    .jet-img{
        margin-bottom: 1em;
        height: 70px !important;

    }

    .start-jet-content{
        width: 100%;
        
       
    }

    .start-jet h2 {
        font-size: 28px;
        text-align: center;
        line-height: 32px;
       
    }

    .start-jet p {
        font-size: 14px;
        line-height: 22px;
    }


    .start-jet-buttons{
        margin-top: 1em;
        
        
        gap: 0.1em;
    }

    .start-jet-contact{
        display: none;

    }

   .start-jet-register span,.start-jet-register div{
           
        padding: 0 1em;  
        font-size: 16px; 
    }
    
    
}




.my-form {
    min-width: 73%;
    padding: 30px;
}

.form-control input,
.form-control button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    background-color: #F6F6F6;
    color: #bababa;
    border-radius: 3px;
    margin: 7px 0px;
    outline-color: var(--prm-color);

}

/*  */
.form-control input[type='submit'],
.form-control button {
    background-color: var(--prm-color);
    color: #fff;
}

/*  */
.form-control button {
    margin-top: 30px;
    font-weight: 600;
}


.bg-shadow.two {
    margin: -60px auto 0px;
    width: 80%;
    height: 15px;
    border-radius: 0px  0px 20px 20px;
    margin-bottom: 50px;
    display: none;
}

.start-jet.two {
    background-image: none;
    margin: 0px auto 60px;
    max-width: 90%;
    /* margin: auto; */
    padding: 0px;
}

.flex-contact {
    display: flex;
    align-items: center;
}

.flex-contact img {
    height: 100%;
    width: 100%;
}

.flex-contact h3 {
    text-align: center;
}

.start-jet.two .flex-contact div{
    padding: 2em ;
}


.form-control input[type='submit']:hover {
    opacity: 0.8;
}

.start-jet .jet-img {
   margin-bottom: 2em;
    height: 150px;
    animation: jetpack 3s ease-in-out infinite;
}

@keyframes jetpack {
    from {
        transform: translateY(50px);
    }

    to {
        transform: translateY(0px);
    }
}

.btn-section.two {
    margin: -100px 0px 40px;
}

.prm-color.two {
    background-color: #fff;
    color: #000;
}

.btn-dark.two {
    background-color: transparent;
    border: 1px solid #fff;
}

.bg-shadow {
    background-color: #FF6B00;
    height: 60px;
    width: 97%;
    margin: 0px auto -50px;
    border-radius: 20px;
}

.vessel {
    background-color: #000;
    color: #fff;
}

.vessel a {
    color: #fff;
}

.vessel a:hover {
    color: var(--prm-color);
}

.grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    margin: 30px 0px;
}

.grid div:nth-child(1) {
    grid-column: 1 / span 3;
    max-width: 300px;
}

.flex.icon {
    justify-content: flex-start;
    gap: 0px;
}

.flex.icon a {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 13px 18px rgba(16.80, 18.63, 35.06, 0.08);
}

.flex.icon a img {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.grid div p {
    margin: 15px 0px;
    line-height: 1.5rem;
    font-size: 15px;
}

.grid div p a {
    font-weight: 300;
    font-size: 14.6px;
}

.grid div h3 {
    font-weight: 500;
}

.flex.copy-right {
    justify-content: space-between;
    margin: 30px 0px 50px;
}

.faqs {
    margin: 65px 50px 0px;
}

.faqs h1,
.about .heading,
.privacy h1,
.terms h1,
.contact h2.contact-header {
    text-align: center;
    margin: 30px 0px; 
}

.about,
.contact {
    margin: 70px 0px 70px;
}

.contact .jet .start-jet.two h2 {
    margin: 0;
    line-height: 1.5em;
}

.contact .jet .start-jet.two p {
    margin-top: 10px;
}


.contact {
    /* margin-bottom: 100px; */
    margin-bottom: 0px;
}

.start-jet.two h2 {
    margin: 0px;
}

.about h3 {
    margin: 10px 0px;
    font-weight: 700px;
    font-size: 28px;
    line-height: 38px;
    font-family: 'Poppins', sans-serif;
  
}

.about img {
    /*height: 500px;*/
    width: 100%;
    margin-bottom: 3em;
    /* filter: brightness(0.8); */
}

.contact .container {
    position: relative;
    margin-bottom: 30px;
}


.msg {
    height: 50px;
    position: absolute;
    right: 0px;
    cursor: pointer;
    bottom: 0px;
}


.accordion {
    margin: 70px 0px;
}

.accordion-card {
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.08);
    margin: 20px 0px;
    cursor: pointer;
    /* height: 70px; */
    transition: 1.5s;
}

.accordion-card p {
    display: none;
}

.accordion-card.active p {
    display: block;
}

.accordion-card p {
    padding: 20px;
}

.accordion-card .flex {
    justify-content: space-between;
}

.more-qs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 80px 0px;
}

.more-qs p {
    margin-bottom: 20px;
}

.hamburger {
    display: none;
    z-index: 1000;
}

.bar {
    transition: 0.5s;
    width: 20px;
    display: block;
    height: 2px;
    background-color: #333;
    margin: 6px 0px;

}

.hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
    width: 25px;
}

.hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
    width: 25px;
}

.hamburger.active .bar:nth-child(2) {
    opacity: 0;
}

.bar:nth-child(1) {
    width: 30px;
}

.bar:nth-child(3) {
    width: 10px;
}

/* Error Screen */

.error-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: auto 30px;
}

.error-container img{
    height: 500px;
}

.error-container p {
    color: #828282;
    font-size: 18px;
    margin: 0;
}

.error-container .btn-section .btn {
    border-radius: 30px;
    margin-bottom: 100px;
}

@media(max-width: 1500px) {

    .platform-img-shadow{
        width: 100%;
    }
    
    .platform-img img{
        
        width: 100%;
       height: 100%;
    }
}

@media(max-width: 1250px) {
    .heropic{
        max-width: 500px;
        /* max-height: 500px; */
    }
}

@media(max-width: 1000px) {

    .developers h2 {
        margin-top: 130px;
    }


    .platform-text h2 {
        font-size: 38px;
        line-height: 52px;
    }

    .platform-img-shadow{
        width: 100%;
    }
    
    .platform-img img{
        
        width: 100%;
       height: 100%;
    }

    .logo img {
        height: 45px;
    }

    a {
        font-size: 14px;
    }

    .btn,
    .btn-prm {
        padding: 10px 15px;
    }

    /* 
    .btn-section {
        gap: 0px;
    } */

    .hero{
        gap: 0px;
    }

    .heropic {
        margin-bottom: -80px;
        height: 600px;
        width: 400px;

    }


    .network img {
        height: 100%;
    }


    .card {
        padding: 20px;
    }

    .card.one {
        width: 25%;
    }

    .card.two {
        width: 75%;
        line-height: 2.2rem;
    }

    .set-img {
        height: 35px;
    }

    /* .learn-more div img:nth-child(1) {
        height: 200px;
        width: 250px;
        padding: 30px 0px;
    } */


    /* .learn-more div:nth-child(2) h1 {
        margin: 20px 0px;
    } */

    .flex.img-no img {
        height: 300px;
        width: 250px;

    }

    .platform-txt {
        line-height: 2.5rem;
    }

    .platform-txt button {
        margin-top: 20px;
    }

    .set-img-2 {
        height: 300px;
    }

    .integrate {
        margin: 50px 0px;
    }


    .feed-quote {
        margin: 50px 0px;
    }

    .feed-quote img {
        height: 150px;
    }

    .arr-sml {
        height: 70px;
        width: 70px;
        display: block;
    }

    .start-jet h1 {
        font-size: 2rem;
    }
    
    /* .flex-contact img {
        height: 500px;
    } */
    

   
}

@media(max-width:880px) {

    .error-container img{
        max-height: 300px;
    }

    .error-container p {
        font-size: 16px;
    }
    
    .container-header{
        padding:0 1em;
    }
    
    .about h3 {
  
       font-size: 18px;
    line-height: 28px;
    text-align: center;
  
}

.flex-contact {
    flex-direction: column;
}

/* .flex-contact img {
    height: 450px;
    width: 100%;
} */


    .flex {
        flex-direction: column;
    }

    .flex-2 .flex {
        flex-direction: row;
    }

    .flex-2 .flex.line img {
        width: 25%;
    }

    .container {
        padding: 0px 20px;
    }

    .navbar {
        position: relative;
    }

    .hamburger {
        display: block;
        font-size: 2rem;
        cursor: pointer;
        position: absolute;
        right: 0;
        /* transition: 1s ease-out; */
    }

    .nav-list {
        /* display: none; */
        /* transition: 0.5s; */
        /* width: 100px; */
        transform: translateY(-200px);
      
        
    }

    .active.nav-list {
        /* display: flex; */
        transform: translateY(0px);
        background-color: #fff;
        position: fixed;
        top: 55px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 50px 0px;
        z-index: 100;
        left: 0;
        right: 0;
        transition: 0.5s ease-out;
       
    }

    .nav-list li {
        margin: 15px 0px;
        
    }

    .nav-list li:nth-child(5) {
        border: none;
        margin-right: 0px;
    }

    .aside-info {
        margin: 35px 0px;
    }

    .aside-info h2 {
        font-size: 1.7rem;
    line-height: 2.7rem;
    }

    .aside-info p {
        margin: 10px 0px;
    }

    .btn-section button.btn {
        height: 45px;
        padding: 10px;
    }

    .btn-section img {
        height: 45px;
    }

    .btn-section {
        justify-content: center;
        gap: 10px;
        margin-top: 50px;
    }

    .btn-section .btn-dark {
        height: 45px;
    }

    .heropic {
        width: 100%;
        height: 100%;
        margin-bottom: -60px;
    }
    
    .payment {
        margin-top: 20px;
    }

    .hero {
        margin-bottom: 10px;
    }

    .network img {
        height: 100%;
    }

    .my-carousel.wide-screen {
        display: none;
    }

    .my-carousel.swiper {
        display: block;
    }

    /* .my-carousel.swiper .feed-quote img {
        /* display: block;
        width: 100%;
        object-fit: cover; 
    } */

    .card .flex {
        flex-direction: row;
    }

    .card {
        background-color: #FFF0E3;
        padding: 25px;
        border-radius: 15px;
    }

    .card.one {
        width: 100%;
    }

    .card.one .flex {
        justify-content: space-around;
    }

    .card.two {
        width: 100%;
    }

    .learn-more {
        margin: 50px 0px 10px;
    }

    .learn-more .flex {
        gap: 0px;

    }

    .learn-more .show-tab {
        width: 100%;
    }

    /* .learn-more div img:nth-child(1) {
        width: 95%;
    }

    .learn-more div:nth-child(2) h1,
    .learn-more div:nth-child(2) p {
        max-width: 100%;

    }

    .learn-more div:nth-child(2) h1 {
        margin: 50px 0px 20px;
    } */

    .eclipse {
        height: 150px;
        width: 35%;
    }

    .platform {
        padding: 30px 0px;
    }

    .platform-txt {
        line-height: 2.2rem;
        font-size: 1.3rem;
    }

    .platform-txt p {
        margin: 20px 0px;
    }

    .platform-txt button {
        margin-top: 20px;
    }

    .set-img-2 {
        height: 300px;
    }

    .flex.img-no img {
        height: 450px;
        width: 100%;
    }

    .integrate {
        margin: 50px 0px;
    }

    .integrate .set-img-2 {
        height: 200px;
    }

    p {
            line-height: 1.8em;
    }


    .feed-quote {
        margin: 50px 0px;
        flex-direction: row;
        /* width: 300%; */
    }

    .feed-quote img {
        height: 200px;
        width: 100%;
        /* display: none; */
    }

    .feed-quote .arr-sml {
        height: 60px;
        width: 60px;
        cursor: pointer;
    }

    .feed-img-top {
        height: 150px;
        width: 35%;
    }

    .feed-img-bottom {
        height: 150px;
        width: 35%;
    }


    .start-jet {
        padding: 60px 30px 30px;
        line-height: 2.3rem;
        margin-bottom: 60px;
    }

    .start-jet div:nth-child(1) {
        max-width: 100%;
        margin-bottom: 0px;
    }

    .start-jet h1 {
        font-size: 1.9rem;
    }

   

    .btn-section button.btn {
        width: 100%;
        font-size: 15px;
        justify-content: center;
    }
    
    .btn-section img {
        margin-right: -45px;
    }
    
    .btn-section {
        justify-content: center;
        gap: 50px;
        margin-top: 40px;
        width: 100%;
    }
    

    .btn-section.two {
        flex-direction: row;
        margin: 0px 0px 20px;
    }

    .bg-shadow {
        margin: 0px auto -55px;
    }


    .grid {
        grid-template-columns: 1fr;
        margin: 30px 0px;
        width: 100%;
    }

    .grid div {
        margin: 15px 0px;
    }

    .grid div:nth-child(1) {
        grid-column: 1;
        min-width: 100%;
    }

    .flex.icon {
        flex-direction: row;
    }
    
    .flex.icon a {
        height: 35px;
        width: 35px;
    }

    .grid div p {
        margin: 15px 0px;
    }

    .flex.copy-right {
        margin: 30px 0px 50px;
        flex-flow: column-reverse;
    }

    .faqs {
        margin: 80px 0px 50px;
    }

    .faqs h1 {
        margin: 20px 0px;
        font-size: 1.7em;
    }

    .accordion {
        margin: 50px 0px;
    }

    .accordion-card .flex {
        flex-direction: row;
    }
    .accordion h3 {
        font-size: 1rem;
    }

    .accordion-card img {
        height: 30px;
    }

    .more-qs {
        margin: 50px 0px;
    }

    .more-qs p {
        margin-bottom: 20px;
    }

    .hero .flex-2 .flex.line {
        display: none;
    }

    .hero {
        margin: 70px 0px 0px;
    }

    .network img {
        margin: 30px 0px;
    }


    /*.about img {*/
    /*    height: 250px;*/
    /*}*/

  
    .msg {
        height: 40px;
        padding-right: 30px;
    }
}



.privacy h1,.terms h1 {
    text-align: center;
    margin: 30px 0px;
}

.privacy,.terms{
    margin:5em 0;
}

.privacy h2,.terms h2{
    margin:0.4em 0;
    
}

.privacy p,.terms p, .terms ul li, .privacy .container ul li{
    text-align:justify;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    line-height: 35px;
   
}

@media(max-width:880px) {
    
    .privacy p,.terms p, .terms li{
    font-size: 14px;
    line-height: 28px;
   
}


.platform-text h2 {
    font-size: 30px;
    line-height: 35.93px;
    margin-bottom: 0.5em;
}

.platform-text p {
    line-height: 28.24px;
    font-size: 17px;
}

    
}
